import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import lightningIcon from '../../assets/images/icon-lightning-yellow.svg';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import selectDatesContent from '../../content/ui/screens/select-dates/selectDates';
import withContent from '../../hoc/with-content/withContent';
import useCoverInformation from '../../hooks/useCoverInformation';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';

import './SelectDates.scss';

const contentMap = {
    heading: 'ui.heading',
    cta: 'ui.cta',
};

interface SelectCoverProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectDates: FC<SelectCoverProps> = ({ content }) => {
    const location = useLocation<PurchaseState>();
    const coverInformation = useCoverInformation(location.state.selectedProductOption!.representedByCoverCode);
    const history = useHistory();

    // redirect user to select product grouping or product option if none found
    useEffect(() => {
        if (location.state.selectedProductGrouping == null || location.state.selectedProductOption == null) {
            history.push(Routes.SELECT_COVER_BAYMAX);
        }
    }, [location.state.selectedProductGrouping, location.state.selectedProductOption]);

    const handleContinue = () => {
        // TODO
    };

    return (
        <Layout title={content.heading} showBackButton className="select-dates__layout">
            {coverInformation?.datePickerContent.datePickerInstructions.length && (
                <ul className="select-dates-instructions">
                    {coverInformation?.datePickerContent.datePickerInstructions.map((item) => (
                        <li key={item} className="select-dates-instructions__item">
                            <img src={lightningIcon} alt="" className="select-dates-instructions__item__icon" />
                            <span>{item}</span>
                        </li>
                    ))}
                </ul>
            )}
            <Sticky>
                <Button label={content.cta} onClick={handleContinue} width="full" />
            </Sticky>
        </Layout>
    );
};

export default withContent(SelectDates, contentMap, selectDatesContent);
